<template>
    <div class="py-5 faq" id="faq">
        <div class="container">
            <div class="row position-relative py-5" id="accordionExample">
                <div class="col-12 col-lg-8 offset-lg-2 text-center mb-4 cap metal-thing-sec">
                    <h2 class="text-white ">Kérdésed van?</h2>   
                </div>
                <div class="col-12 col-lg-8 offset-lg-2" v-for="(item, index) in questions" :key="index">
                    <button 
                    class="btn btn-collapse collapsed font-primary"  
                    type="button" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="'#collapse'+index" 
                    aria-expanded="false" 
                    :aria-controls="'#collapse'+index">
                        {{ item.question }}
                    </button>
                    <div class="collapse font-secondary fs-14" :id="'collapse'+index" aria-labelledby="headingOne" data-bs-parent="#accordionExample" v-html="item.answer">
                    </div>
                </div> 
            </div>       
        </div>
    </div>
</template>

<script>

export default {
    
  mounted() {

      this.$store.dispatch("getQuestions");
  },
  computed:{
    questions(){
      return this.$store.state.questions;
    },
  },
}
</script>
<template>
  <div id="codefilling-form">
    <div class="codefilling-inner-row container" id="form">
      <!-- FORM -->
      <ValidationObserver
          v-if="formStatus === false && !maximumUploads && !maximumDailyUploads"
          v-slot="{ handleSubmit }"
          tag="div"
      >
        <form
            id="promotionCodeForm"
            class="form form--codefilling row position-relative"
            @submit.prevent="handleSubmit(formSubmit)">
            <div class="col-12 col-lg-10 offset-lg-1 bg-white custom-padding-form" id="palyazat" style="border-radius:20px 20px 0 0;">
                <div class="codefilling-form codefilling-form--inner-wrap">
                    <div class="text-center py-5 mt-5">
                        <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                            <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                        </div>
                        <h3 class="text-primary metal-thing-right">Kódfeltöltés</h3>
                        
                    </div>
                    <div class="row my-3">
                        <div class="col-12 col-lg-6 order-1 order-lg-2">
                            <div class="px-md-4">
                                <CustomInput
                                    label="Vezetéknév"
                                    name="surname"
                                    placeholder="pl.: Molnár"
                                    tooltip="A játékos személyi igazolványába jegyzett vezetéknév."
                                    v-model="form.surname"
                                    rules="required|min:2|customRegex"
                                    :disabled="alreadySigned"
                                />  
                                <CustomInput
                                    label="Keresztnév"
                                    name="forename"
                                    placeholder="pl.: Kata"
                                    tooltip="A játékos személyi igazolványába jegyzett keresztnév."
                                    v-model="form.forename"
                                    rules="required|min:2|customRegex"
                                    :disabled="alreadySigned"
                                />
                                <CustomInput
                                    label="E-mail cím"
                                    name="email"
                                    placeholder="pl.: molnarkata@gmail.com"
                                    tooltip="A játékos e-mail címe."
                                    v-model="form.email"
                                    rules="required|email"   
                                    :disabled="alreadySigned"                                 
                                />
                                
                                <div class="col-12 phone-wrap">
                                    <div class="form-label">Telefonszám</div>
                                    <div class="d-flex w-100 align-items-start position-relative">
                                        <span class="me-2 d-block phone-prefix">+36</span>
                                        <CustomInput
                                            class="w-100"
                                            label=""
                                            name="phone"
                                            tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                            v-model="phoneShort"                                        
                                            @input="formatPhoneNumber($event)"                                        
                                            rules="customPhone"
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 order-2 order-lg-1 upload-code">
                            <div class="p-4" style="background:#00793B;border-radius:10px;">
                                <CustomInput
                                    label="Promócios kód"
                                    name="code"
                                    v-mask="{mask: 'SSSSSS', tokens: hexTokens}"
                                    placeholder=""
                                    tooltip="A nyitófülön, kupakban vagy kuponon található 6 jegyű kód."
                                    v-model="form.code"
                                    @keyup="uppercase"
                                    rules="required|min:6"
                                />
                                <div class="col-12 mb-4 mt-4">
                                    <DropDown
                                        label="Hol vásároltad a terméket?"
                                        name="where_bought"
                                        placeholder="Válassz"
                                        :options="shops"
                                        @selectedItem="form.where_bought = $event"
                                        rules="required"
                                    />
                                </div> 
                                <img src="@/assets/imgs/code.png" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-10 offset-lg-1 custom-padding-form" style="background: rgb(234, 252, 244);border-radius:0 0 20px 20px;">
            <div class="upload-bg">
              <div class="col-12 winner-codefilling__text ">

                <div class="col-12">
                    <CheckBox
                        class="mt-4"
                        name="condition"
                        id="condition1"
                        v-model="form.condition"
                        :checked="form.condition"
                        rules="required|acceptConditions"                                  
                    >
                        <p 
                            class="mb-0 text-black font-secondary"
                        >
                            Kijelentem, hogy 18 éves elmúltam, a <span>&nbsp;</span>
                            <a class="text-primary text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzatot</a>
                            <span>&nbsp;</span> elolvastam, annak feltételeit elfogadom, és az adatkezeléssel kapcsolatos, a 14. pontban foglalt tájékoztatást kifejezetten tudomásul vettem.
                            <span class="text-red fw-bold">*</span>
                        </p>
                    </CheckBox>
                </div>

                <!-- ÁSZF ELFOGADÁSA -->
                <div class="col-12">
                    <CheckBox
                        class="mt-2"
                        name="consent"
                        id="consent1"
                        v-model="form.consent"
                        :checked="form.consent"
                        rules="required|acceptConditions"
                    >
                        <p class="mb-0 text-black font-secondary"> 
                            <span>Hozzájárulok ahhoz, hogy a nyertesként történő kisorsolásom esetén a szervező a vezetéknevem kezdőbetűjét és a keresztnevemet, a nyerés tényét, valamint a nyeremény megjelölését az alábbi honlapon közzé tegye: <a class="text-primary text-underline fw-700" href="https://www.heineken.com/hu/hu/nyeremenyjatek">heineken.com/hu/hu/nyeremenyjatek</a> Az adatkezelésre vonatkozó részletes információkat a hivatalos </span>
                            <span>&nbsp;</span>
                            <a class="text-primary text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzat 14. pontja</a>
                            <span>&nbsp;</span>
                            <span>tartalmazza.</span>
                            <span class="text-red fw-bold">*</span>    
                        </p>
                    </CheckBox>
                </div>

                <!-- FELIRATKOZÁS A HÍRLEVÉLRE -->
                <div class="col-12">
                    <CheckBox
                        class="mt-2"
                        name="newsletter"
                        id="newsletter1"
                        v-model="form.newsletter"
                        :checked="form.newsletter"
                        @input="changeMarketing()"
                    >
                        <div>
                            <p class="text-black font-secondary">Hozzájárulok ahhoz, hogy az Adatkezelő közvetlen marketing célú megkereséseket küldjön a részemre, például email-ben, vagy egyéb módon (opt-in).</p>
                        </div>
                    </CheckBox>
                </div>
                
                <div class="col-12 col-md-6">
                    <SelectDate
                        v-if="form.newsletter === 1"
                        name="birth_date"
                        label="Kérlek add meg a születési dátumod" 
                        placeholder="ÉÉÉÉ-HH-NN"
                        @selectedDate="form.birth_date = dateTimeToSqlFormat($event)"
                        :rules="form.newsletter != 0 ? 'required':''"
                    />
                    <span v-if="!overEighteen" class="error input-error">
                      A feliratkozáshoz be kell töltened a 18. életévedet.
                  </span>
                </div>

                <div class="py-5 my-5 text-sm-center d-flex">                                
                        <span class="text-red fw-bold">*</span>                                    
                        <p class="m-0 info-text text-black font-secondary fs-14">A csillaggal jelölt mezők kitöltése szükséges.</p>
                </div>  

              <!--  <ValidationProvider
                    v-if="form.marketing === '1'"
                    class="mb-4 col-12 birthDate--marketing"
                    rules="required|isOver18"
                    v-slot="{ failedRules }"
                    tag="div"
                >
                  <div class="form-label">Kérlek add meg a születési dátumod.<span class="ml-1 error">*</span></div>
                  <input
                      v-mask="'####-##-##'"
                      v-model="form.birthDate"
                      type="text"
                      placeholder="0000-00-00"
                      class="form-control"
                      name="Keresztnév"
                      id="birthDate"
                  />
                  <span class="error">{{
                      getErrorMessage("birthDate", Object.entries(failedRules)[0])
                    }}</span>
                  <span v-if="!overEighteen" class="error">
                    A feliratkozáshoz be kell töltened a 18. életévedet.
                  </span>
                </ValidationProvider>-->

                <div class="text-center pb-5 send-form-btn">
                  <button type="submit" class="btn btn-secondary" @click="gtag_report_conversion()">
                    Feltöltöm a kódom
                    <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                  </button>
                </div>
              </div>
            </div>
          </div>


        </form>
      </ValidationObserver>
      <div
           v-if="formStatus"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-8 offset-lg-2"
      >
        <div v-if="winnerMessage" class="codefilling-form p-3 codefilling-form--inner-wrap border-radius-message-card form-error-message bg-white">
            <div class="col-12 m-auto text-center order-nyertes p-0">
                <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                    <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                </div>
                <Animation type="nyert"/>
                <div class="main-h1-notwin text-primary fw-700 mt-0">Gratulálunk!</div>
                <div class="text mb-5 py-4">
                    <div class="fw-bold fs-20 font-primary mb-4" style="color:#064D2B;">Érvényes pályázat esetén megnyerted <br><span class="fs-28" v-if="winnerMessage_daily">napi nyereményeink egyikét.</span><span class="fs-28" v-else>heti nyereményeink egyikét.</span><br></div>
                    <div class="fs-24 fw-bold font-primary" style="color:#064D2B;">Mit kell tenned ezután?</div>
                    <div class="w-75 mx-auto">
                      <p class="text-black fs-16">Figyeld a postafiókodat (a promóciós üzeneteket és a spamet gyűjtő mappákat is), mert a nyerteseket e-mailben értesítjük.<br>Tölts fel újabb kódokat 2024. július 17-én éjfélig és növeld nyerési esélyed a fődíjra!</p>
                      <p class="text-black fs-16">Ne feledd: 10 érvényes kóddal részt vehetsz extra nyereményjátékunkban is!</p>
                    </div>
                </div>
                <div class="btn-wrap justify-content-center m-md-1">
                    <button class="mt-0  btn btn-secondary btn-primary--large"
                            @click="backToForm();">
                    Újabb kódot töltök fel
                    <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!winnerMessage && !uploadedError" class="codefilling-form p-3 codefilling-form--inner-wrap border-radius-message-card form-error-message bg-white">
            <div class="col-12 m-auto text-center order-nyertes p-0">
                <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                    <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                </div>
                <Animation type="nem_nyert"/>
                <div class="main-h1-notwin text-primary fw-800 mt-0 mb-4">Sajnos most nem nyertél!</div>
                <div class="text mb-3">
                    <div class="text-primary fw-bold">De semmi gond: 2024. július 17-én éjfélig újrapróbálkozhatsz és növelheted nyerési esélyed a fődíjra.</div>
                    <div class="text-primary mt-3">Tölts fel legalább 10 érvényes kódot 2024. június 16-ig és vegyél részt extra nyereményjátékunkban is!</div>
                </div>
                <div class="btn-wrap justify-content-center mt-5">
                    <button class="mt-0  btn btn-secondary btn-primary--large"
                            @click="backToForm();">
                    Újabb kódot töltök fel
                    <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                    </button>
                </div>
            </div>
        </div>
      </div>
      <!-- OTHER SCREENS -->
      <div
          v-if="maximumDailyUploads"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-6 offset-lg-3"
      >
        <div
            class="codefilling-form p-3 codefilling-form--inner-wrap border-radius-message-card form-error-message bg-white ">
          <div
              class="col-12 m-auto text-center order-nyertes"
              v-if="maximumDailyUploads"
          >
            <!-- Error message - maximum feltöltés -->
            <div class="main-h1-notwin mt-0 bg-red">Sikertelen kódfeltöltés!</div>
            <div class="winning-img--error">
              <img class="img-fluid" src="@/assets/imgs/sikertelen.png" alt=""/>
            </div>
            <p class="font-secondary">
              Naponta maximum 3 kódfeltöltésre van lehetőség. Próbálkozz újra holnap. </p>
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-primary btn-primary--green"
                      @click="backToForm();">
                Főoldal
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
          v-if="maximumUploads"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-6 offset-lg-3"
      >
        <div
            class="codefilling-form p-3 codefilling-form--inner-wrap border-radius-message-card form-error-message bg-white ">
          <div
              class="col-12 m-auto text-center order-nyertes"
              v-if="maximumUploads"
          >
            <!-- Error message - maximum feltöltés -->
            <div class="main-h1-notwin mt-0 text-red">Sikertelen kódfeltöltés!</div>
            <div class="winning-img--error">
              <img class="img-fluid" src="@/assets/imgs/sikertelen.png" alt=""/>
            </div>
            <p class="font-secondary">
              Sajnos elérted a promóció maximális feltöltési lehetőségét.</p>
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-secondary btn-primary--green"
                      @click="backToForm();">
                Főoldal
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
          v-if="formStatus && !maximumUploads && !maximumDailyUploads"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-6 offset-lg-3"
      >
        <div v-if="(uploadedError === true || alreadyUploadedCode) && !maximumUploads" class="codefilling-form p-3 codefilling-form--inner-wrap border-radius-message-card form-error-message bg-white">
          <div class="col-12 m-auto text-center order-nyertes p-5">
            <!-- SIKERTELEN BEKÜLDÉS - érvénytelen AP kód -->
            <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                  <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
              </div>
            <div class="main-h1-notwin mt-0 text-red fw-bold mb-4">Sikertelen kódfeltöltés</div>
            <!--<div class="winning-img--error">
              <img src="@/assets/imgs/sikertelen.png" class="img-fluid" alt=""/>
            </div>-->
            <!--                        <div class="text-red">Mit kell tennie ezután?</div>-->
            <p class="email-info-text--small puls-padding-apcode font-secondary">
              Lehet, hogy elütöttél valamit, vagy ezzel a kóddal már játszottál.
              Ellenőrizd a kódot, és próbáld meg újra!</p>
            <p class="text-primary font-secondary fw-bold mt-2">Naponta maximum 3 kódfeltöltésre van lehetőség!</p>
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-secondary btn-primary--green"
                      @click="backToForm();">
                Megpróbálom újra
                <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
              </button>
            </div>
          </div>


          <!-- SIKERES BEKÜLDÉS -->
          <!--<div
              v-if="!winner && !uploadedError && !alreadyUploadedCode && !maximumUploads && !maximumDailyUploads"
              class="col-12 m-auto text-center order-nyertes p-5"
          >
            <div class="main-h1-notwin  mt-0" style="background-color: #078D50;">Sikeres kódfeltöltés</div>
            <div class="text mb-3">
              <div class="nem-nyert-img-wrap">
                <img src="@/assets/imgs/sikeres.png" class="img-fluid nem-nyert-img"/>
              </div>


              <div class="text-primary fw-bold font-primary text-uppercase fs-24">
                Mit kell tenned ezután?
              </div>
              <p class="font-secondary text-black">
                Figyeld a postafiókodat (a promóciós üzeneteket és a spamet gyűjtő mappákat is), mert a nyerteseket
                e-mailben értesítjük!
              </p>
            </div>
            <div class="row ml-auto justify-content-center mr-auto mt-4">
              <div class="btn-wrap justify-content-center m-md-1">
                <button class="mt-0  btn btn-primary btn-primary--green"
                        @click="backToForm();setGTM()">
                  Újabb kódot töltök fel
                  <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                </button>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import axios from "axios";
import CheckBox from '@/components/base/CheckBox.vue'
import SelectDate from '@/components/base/SelectDate.vue'
import DropDown from '@/components/base/DropDown.vue'
import Animation from '@/components/Animation.vue'
import CustomInput from '@/components/base/CustomInput.vue'

/* eslint-disable */
import {ValidationObserver} from "vee-validate";
import {
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";
import {required, email, regex, confirmed} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import errorMessages from "@/assets/errorMessages.json";

// Import date picker css

setInteractionMode("lazy");
// Add a validation rules
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Legalább 2 karakter szükséges.",
});
extend("minAz", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Az azonosító szám formátuma nem megfelelő.",
});
extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  message: "A Játékszabályzatot kötelező elfogadnia. ",
});

//18 elmúlt
extend("isOver18", {
  validate(value) {
    let date = value.split('-')
    console.log(date)
    let birthDateY = parseInt(date[0]);
    let birthDateM = parseInt(date[1]);
    let birthDateD = parseInt(date[2]);

    var birthdate = new Date();
    birthdate.setFullYear(birthDateY, birthDateM - 1, birthDateD);

    var currdate = new Date();
    currdate.setFullYear(currdate.getFullYear() - 18);

    console.log('setfullyear', currdate, birthdate, (currdate - birthdate))

    if ((currdate - birthdate) < 0) {
      return false;
    } else {
      return true;
    }
  },
  message: "Csak 18 év felett lehetséges hírlevélre feliratkozni ",
});

extend("email", {
  ...email,
  message: "Az e-mail mező csak érvényes e-mail címet tartalmazhat.",
});
extend("required", {
  ...required,
  message: "{_field_} megadása kötelező.",
});
extend("confirmed", {
  ...confirmed,
  message: "Nem megegyező email cím.",
});

export default {
  data() {
    const date = new Date();
    //const purchaseDate = `${this.dateValue} + ${this.timeValue}`
    return {
      alreadySigned: false,
      dateValue: '',
      timeValue: '',
      formatted: '',
      selected: '',
      locale: 'hu',
      weekday: 1,
      context: {
        "hourCycle": "h23",
        "locale": "hu"
      },
      hexTokens: {
        S: {
            pattern: /[0-9a-zA-Z]/,
            transform: v => v.toLocaleUpperCase()
        }
      },
      labels: {
        hu: {
          labelPrevDecade: 'Előző évtized',
          labelPrevYear: 'Előző év',
          labelPrevMonth: 'Előző hónap',
          labelCurrentMonth: 'Jelenlegi hónap',
          labelNextMonth: 'Következő hónap',
          labelNextYear: 'Következő év',
          labelNextDecade: 'A következő évtized',
          labelToday: 'Ma',
          labelSelected: 'Kiválasztott dátum',
          labelNoDateSelected: 'Nincs dátum kiválasztva',
          labelCalendar: 'Naptár',
          labelNav: 'Naptár navigáció',
          labelHelp: 'A nyílbillentyűkkel navigálhat a naptárban',
          labelHours: 'órák',
          labelMinutes: 'percek',
          labelSeconds: 'másodpercek',
          labelIncrement: 'emel',
          labelDecrement: 'Csökkent',
          labelNoTimeSelected: 'Nincs kiválasztva az idő',
          labelCloseButton: 'Bezárás'
        }
      },
      show: false,
      codeUpperCase: "",
      phoneShort: '',
      shops: [
        "Tesco","Spar/Interspar","Auchan","Aldi", "CBA","Lidl", "Penny Market", "Vendéglátóhely", "Kisbolt/dohánybolt","Coop","Kifli","Foodora","Tesco Online","Egyéb"
      ],
      form: {
        code: "",
        phone: "",
        surname: "",
        forename: "",
       // purchase_date: "",
        email: "",
        //emailConfirm: "",
        condition: null,
        consent: null,
        newsletter: 0,
        birthDate: '',
        where_bought: '',
        receipt: null,
        receipt2: null,
        receipt3: null,
        receipt4: null,
        receipt5: null,
      },
      winnerMessage: false,
      winnerMessage_daily: false,
      overEighteen: true,
      responseErrors: null,
      uploadedError: false,
      alreadyUploadedCode: false,
      hash: undefined,
      winType: undefined,
      winner: undefined,
      formIsSubmitting: false,
      maximumUploads: false,
      maximumDailyUploads: false,
      imageError: false,
      uploadedFiles: [],
      formStatus: false,
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    CheckBox,
    SelectDate,
    DropDown,
    Animation,
    CustomInput
  },
  directives: {
    mask,
  },
  computed: {
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
  watch: {
    formStatus(newData) {
      window.scroll(
          0,
          this.findPos(document.getElementById("codefilling-form")) - 76
      );

      this.$parent.backgroundChanger = newData
      // $(".purchase_date-row")
      //     .find("input")
      //     .attr("placeholder", "Vásárlás időpontja");
    },
    '$store.state.user': function() {
      console.log('sendform change user data')
        if(this.$store.state.user){
            this.alreadySigned = true;
            
            this.form.surname = this.$store.state.user.surname;
            this.form.forename = this.$store.state.user.forename;
            this.form.email = this.$store.state.user.email;
            this.phoneShort = this.$store.state.user.phone ? this.$store.state.user.phone.slice(4) : ''
            
        }else{
            this.alreadySigned = false;
        }
    }
  },
  methods: {
    /*setGTM() {
         window.dataLayer.push('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-9373807/soproni/2024_003+standard'
        });      
    },*/
    uppercase() {
      this.form.code = this.form.code.toUpperCase();
    },   
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
      this.context = ctx
    },
    passEvent() {
      this.$emit('formStatus', true)
    },
    handleFilesUpload() {
      let _this = this;
      //remove the error msg if there was
      this.$refs.uploadederror.innerHTML = "";

      let uploadedItem = this.$refs.receipt.files[0];

      //check file size 5Mb max
      let maxFileSize = 1024 * 1024 * 5;
      let fileSize = uploadedItem.size;

      //check file fomat
      // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

      //check file size - max 2Mb
      if (fileSize > maxFileSize) {
        this.$refs.uploadederror.innerHTML =
            "Túl nagy a fájl mérete (max. 5Mb)";
        // } else if (isGoodExtension == false) {
        //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
      } else {
        if (_this.uploadedFiles.length < 2) {
          //max 5 item could be uploaded

          let nameIndex = _this.uploadedFiles.find(
              (item) => item.name == uploadedItem.name
          );

          if (nameIndex == undefined) {
            _this.uploadedFiles.push(uploadedItem);
          } else {
            this.$refs.uploadederror.innerHTML = "Megegyező fájl név.";
          }
        } else {
          _this.$refs.uploadederror.innerHTML = "Nem tölthető fel több fájl.";
        }
      }

      this.fillReceipts();

      //set the default state for the input type=file
      this.$refs.receipt.value = ""

      if (this.form.receipt != null) {
        this.imageError = false;
      }
    },
    fillReceipts() {
      let _this = this;

      _this.form.receipt = null;
      _this.form.receipt2 = null;
      _this.form.receipt3 = null;
      _this.form.receipt4 = null;
      _this.form.receipt5 = null;

      this.uploadedFiles.forEach(function (item, i) {
        if (i == 0) {
          _this.form.receipt = item;
        } else if (i == 1) {
          _this.form.receipt2 = item;
        } else if (i == 2) {
          _this.form.receipt3 = item;
        } else if (i == 3) {
          _this.form.receipt4 = item;
        } else if (i == 4) {
          _this.form.receipt5 = item;
        }
      });
    },
    delUploadedFile(delItem) {
      let _this = this
      let delItemIndex = this.uploadedFiles.indexOf(delItem);

      if (delItemIndex != -1) {
        _this.uploadedFiles.splice(delItemIndex, 1);
      }

      this.$refs.uploadederror.innerHTML = "";
      this.fillReceipts();
    },
    removeError(fieldName) {
      document.querySelector(`#promotionCodeForm .${fieldName}-row .error-2`).remove();
    },
    getErrorMessage(fieldName, violation) {
      return violation
          ? (errorMessages[fieldName] &&
              errorMessages[fieldName][violation[0]]) ??
          violation[1]
          : undefined;
    },
    findPos(obj) {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [curtop];
      }
    },
    formSubmit() {
        let _this = this

        if (this.phoneShort){
            this.form.phone = '+36-' + this.phoneShort
        }


        //check that the form was submitted
        if (!_this.formIsSubmitting) {
        _this.formIsSubmitting = true

        let formData = new FormData();

        if(this.alreadySigned){
            formData.append("surname", _this.$store.state.user.surname);
            formData.append("forename", _this.$store.state.user.forename);
            formData.append("email", _this.$store.state.user.email);
        }else{
            formData.append("surname", _this.form.surname);
            formData.append("forename", _this.form.forename);
            formData.append("email", _this.form.email);
        }

        formData.append("phone", _this.form.phone);
        if ( parseInt(_this.form.newsletter) === 1) {
          formData.append("birth_date", _this.form.birth_date);
        }
        
        formData.append("code", _this.form.code);
        formData.append("condition", _this.form.condition);
        formData.append("where_bought", _this.form.where_bought);           
        formData.append("newsletter", _this.form.newsletter);
        formData.append("consent", _this.form.consent); 

        axios.post(process.env.VUE_APP_API_URL + 'step1/upload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
                // console.log(response.data)

                if (response.data.status) {
                    var _this = this;

                    if(response.data.winner == 1){
                        _this.winnerMessage = true;
                        if(response.data.prize == 'daily'){
                            _this.winnerMessage_daily = true;
                        }
                    }
                    

                    _this.GTtrackGA4({
                      'event' : 'event',
                      'category': 'kodfeltoltes',
                      'action': 'gomb_kattintas',
                      'label': 'Feltöltöm a kódom',                
                      'button': 'kodfeltoltes',
                      'clicked_text':'Feltöltöm a kódom',
                      'action_type': 'gomb_kattintas',
                      'success': 'sikeres'
                  });

                if(this.$store.state.isMarketingCookieActive){
                   // console.log("marketing cookie lefut");
                    window.fbq('trackCustom', 'UploadCode');

                    window.gtag('event', 'conversion', {
                      'allow_custom_scripts': true,
                      'send_to': 'DC-10798373/lead/hucrcode+unique'
                    });
                }
                
                    //show the win or not win block instead of the codefilling form
                    _this.formStatus = true;
                    _this.$store.state.codeMessage = true;
                    _this.passEvent();                        
                    _this.backToForm(true);
                    _this.$store.state.myDataKey += 1
                    _this.$emit('compSend')
                    _this.checkLoggedIn()
                } else {
                  
                  //  document.querySelector("#promotionCodeForm .form-subtitle").append(
                    // '<div class="error-2">Hiba történt, kérjük, próbáld újra.</div>'
                //);
                /*$("#promotionCodeForm .form-subtitle").append(
                    '<div class="error-2">Hiba történt, kérjük, próbáld újra.</div>'
                );*/
                }
                _this.formIsSubmitting = false
            }).catch(function (error) {

                _this.GTtrackGA4({
                'event': 'event',
                'category': 'kodfeltoltes',
                'action': 'gomb_kattintas',
                'label': 'sikertelen',
                'button': 'kodfeltoltes',
                'clicked_text': 'kodfeltoltes',
                'success': 'sikertelen'
                });

                document.getElementById('promotionCodeForm').scrollIntoView(true);
                _this.formIsSubmitting = false
                console.log(error);
                if (!error.response.data.status) {
                let errorsArray = Object.entries(error.response.data.error);

                //remove the errors divs
                /* if ($("#promotionCodeForm .error-2").length > 0) {
                    $("#promotionCodeForm .error-2").each(function () {
                    $(this).remove();
                    });
                }*/
                /*if (document.querySelector("#promotionCodeForm .error-2").length > 0) {
                    document.querySelector("#promotionCodeForm .error-2").each(function () {
                    document.querySelector(this).remove();
                    });
                }*/
                for (const [errorName, errorValue] of errorsArray) {
                    if (errorName == "code") {
                    //change the form block text
                    _this.formStatus = true;
                    _this.$store.state.codeMessage = true;
                    _this.uploadedError = true;
                    _this.alreadyUploadedCode = true;
                    _this.passEvent();
                    } else if (errorName === "maximum_daily_uploads") {
                      _this.maximumDailyUploads = true;
                    } else if (errorName == 'receipt' || errorName == 'receipt2' || errorName == 'receipt3' || errorName == 'receipt4' || errorName == 'receipt5') {
                    /* $("#promotionCodeForm .receipt-row").append(
                        '<div class="error-2">' + errorValue + "</div>"
                    );*/
                    document.querySelector("#promotionCodeForm .receipt-row").append(
                        '<div class="error-2">' + errorValue + "</div>"
                    );
                    } else if (errorName === "maximum_uploads") {
                        _this.maximumUploads = true;
                    } else if (errorName === "birth_date") {
                        _this.overEighteen = false
                    } else {
                    /*$("#promotionCodeForm ." + errorName + "-row").append(
                        '<div class="error-2">' + errorValue + "</div>"
                    );*/

                    document.querySelector("#promotionCodeForm ." + errorName + "-row").append(
                        '<div class="error-2">' + errorValue + "</div>"
                    );
                    }
                }
                }
            });
        } else {
          console.log('double form submit')
        }


        // .catch(() => {
        //     console.log('Form submission cancelled');
        //   })
      
    },
    gtag_report_conversion(url){
            var callback = function () {
                if (typeof(url) != 'undefined') {
                window.location = url;
                }
            };
            window.gtag('event', 'conversion', {
                'send_to': 'AW-775738373/2qZ6CNOH0q0ZEIWo8_EC',
                'event_callback': callback
            });
            return false;

    },
    changeMarketing(){
        if ( parseInt(this.form.newsletter) === 0){
            this.form.birth_date = ''
        }
    },
    backToForm(skipFormStatusReset) {

      this.form.surname = "";
      this.form.forename = "";
      this.form.code = "";
      this.form.birth_date = "";
      this.form.phone = "";
      this.form.purchase_date = "";
      this.form.email = "";
      this.form.emailConfirm = "";
      this.form.condition = "";
      this.form.newsletter = 0;
      this.form.consent = "";
      this.uploadedError = false;
      this.alreadyUploadedCode = false;
      this.winner = false;
      this.dateValue = '';
      this.timeValue = '';
      this.winType = undefined;
      this.hash = null;
      this.overEighteen = true;
      this.maximumUploads = false;
      this.maximumDailyUploads = false;
      this.form.receipt = null;
      this.form.receipt2 = null;
      this.form.receipt3 = null;
      this.form.receipt4 = null;
      this.form.receipt5 = null;
      this.formIsSubmitting = false;
      this.uploadedFiles = [];
      this.$store.state.codeMessage = false;
      if (!skipFormStatusReset) {
        this.formStatus = false;
        this.winnerMessage = false;
        this.winnerMessage_daily = false;
      }

      if(this.$store.state.user){
        this.phoneShort = this.$store.state.user.phone ? this.$store.state.user.phone.slice(4) : ''
      } else {
        this.phoneShort= ""
      }
      

      if(this.$store.state.user){
          this.alreadySigned = true;
          
          this.form.surname = this.$store.state.user.surname;
          this.form.forename = this.$store.state.user.forename;
          this.form.email = this.$store.state.user.email;
          this.form.phone = this.$store.state.user.phone;
          
      }else{
          this.alreadySigned = false;
      }

      this.$emit('resetCompSend')
    }

  },
  mounted(){
    let _this = this

    setTimeout(() => {
      // console.log('moutned sendform',  _this.$store.state.user.phone)
      if (_this.$store.state.user.phone){
        _this.phoneShort = _this.$store.state.user.phone.slice(4)  
      }
      
    }, 0);
    
  }
};
</script>

<template>
    <div>
        <Header />          
        <Hero /> 
        <Winnings />         
        <!-- <Rules /> -->
        
        <!-- logged out -->
        <div v-if="!this.$store.state.user && this.$store.state.promoStatus != 'before'">
          <!--  <LogIn v-if="this.$store.state.loginShow" />  -->       
            <Register />                        
        </div>

        <!-- logged in -->
        <div class="send-form-bg" v-if="this.$store.state.promoStatus == 'live'" :style="this.$store.state.user ? 'background-size:contain;':'background-size:cover;'">
            <!-- <SendForm /> -->
            <SendForm @compSend="isNewCompSended = true" @resetCompSend="isNewCompSended = false"/>
            <MySendings :getMyNewSendings="isNewCompSended" v-if="this.$store.state.user"/>
            <MyData v-if="this.$store.state.user" :getMyNewData="isNewCompSended"/>            
        </div>    
        <Products />             
        <Winners v-if="this.$store.state.promoStatus != 'before'" /> 
        <FAQ v-if="this.$store.state.promoStatus != 'after'" />
        <Contact v-if="this.$store.state.promoStatus != 'after'"/>
        <Footer /> 
       <!-- <AgeGateComp />-->
    </div>
</template>

<script>
// import ForgottenEmail from "@/components/ForgottenEmail.vue"
import Header from "@/components/Header.vue"
import Hero from "@/components/Hero.vue"
//import AgeGateComp from "@/components/AgeGateComp.vue"
//import Rules from "@/components/Rules.vue"
//import LogIn from "@/components/LogIn.vue"
//import BeforeHero from "@/components/BeforeHero.vue"
import Register from "@/components/Register.vue"
import SendForm from "@/components/SendForm.vue"
import MySendings from "@/components/MySendings.vue"
import MyData from "@/components/MyData.vue"
import Winners from "@/components/Winners.vue"
import Winnings from "@/components/Winnings.vue"
import FAQ from "@/components/Faq.vue"
import Contact from "@/components/Contact.vue"
import Footer from "@/components/Footer.vue"
import Products from "@/components/Products.vue"

export default {
  components: {
    // ForgottenEmail,
    Header,
    Hero,   
   // BeforeHero, 
    //Rules,
   // LogIn,
    Register,
    Winnings,
    SendForm,
    MySendings,
    MyData,
    Winners,
    FAQ,
    Contact,
    Footer,
    //AgeGateComp,
    Products
  },  
  data(){
    return{
      isNewCompSended : false
    }
  },
   created(){
        if (document.querySelectorAll('meta[content="noindex, nofollow"]').length > 0) { 
            document.querySelector('meta[content="noindex, nofollow"]').remove();
        }
    },
}
</script>
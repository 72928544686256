<template>
    <div id="lottie" style="height:250px;"></div>
</template>
<script>

    import lottie from "lottie-web";
    import nyert from "@/assets/nyert.json";
    import nemnyert from "@/assets/nem_nyert.json";

    export default {
        props: ['type'],
        data() {
            return {
                animationData: null
            }
        },
        mounted(){
            if(this.type == "nyert"){
                this.animationData = nyert;
            }else{
                this.animationData = nemnyert;
            }

            var params = {
                container: document.getElementById('lottie'),
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: this.animationData
            };

            lottie.loadAnimation(params);
        }
    }

</script>
<template>
    <div id="termekek" class="products block--big position-relative">
        <div class="container-xl">                    
            <div class="row py-5 py-md-0">                
                <div class="col-md-12 col-lg-8 offset-lg-2 d-flex flex-column text-center">
                    <h2 class="font-header text-primary text-center mb-7">
                        A játékban részt vevő termékek
                    </h2>
                </div>
                <div class="col-12 flex-column flex-lg-row text-center mb-5">
                    <img src="@/assets/imgs/product.png" alt="Heineken" class="img-fluid" >
                    <img src="@/assets/imgs/product-1.png" alt="Heineken" class="img-fluid mx-3" >
                    <img src="@/assets/imgs/product-2.png" alt="Heineken" class="img-fluid ">
                </div>  
                <div class="col-12 text-center mt-5">                    
                    <a class="btn btn-secondary" @click="setGtm('Termékek listája')" :href="$api_conf.apiDomain+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div>   
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <p class="text-center text-primary mt-7 fs-14" >A képek csak illusztrációk.</p>
                    </div>
                </div>  
            </div>         
        </div>
    </div>
</template>

<script>

export default {
    data() {
      return {
        settings: {
            arrows: true,
            dots: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>
<template>
    <div class="winnings-bg">
        <div id="nyeremenyek" class="winners block--big">
            <h2 class="text-center mt-0 text-white mb-5">
                Nyeremények
            </h2>
            <div class="winners__winning-wrap ">
                <div class="container-xl py-0 position-relative">
                    <div class="row cap-left metal-thing-sec">
                        <div class="col-12 col-lg-10 offset-lg-1 text-center mb-5 ">
                            <div class="d-flex justify-content-between flex-md-row position-relative flex-column p-2 winnings-box" style="border-radius: 40px 40px 40px 0px;">
                                <span class="col-12 position-absolute shadow-box" @mouseover="infoText6 = true"
                                @mouseleave="infoText6 = false"></span>
                                <img src="@/assets/imgs/winner-main.png" class="img-fluid d-none d-md-block">
                                <img src="@/assets/imgs/winner-main-mobile.png" class="img-fluid d-block d-md-none">
                                <div class="position-absolute h-100 d-flex justify-content-center align-items-center winnings-text-box" style="border-radius: 40px 40px 40px 0px;top:0;left:0" v-if="infoText6">
                                    <p class="text-white fw-400 font-primary fs-18">3 napos hosszúhétvége 2 éjszakás szállással és repülővel 4 fő részére 4*-os hotelben, félpanziós ellátással és exkluzív belépővel a Heineken Experience látogatóközpontba.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 col-md-8 offset-md-2 offset-lg-1 text-center">
                            <div class="d-flex justify-content-between flex-md-row position-relative flex-column bg-white p-2 winnings-box" style="border-radius: 0px 40px 40px 40px;">
                                <span class="col-12 position-absolute shadow-box" @mouseover="infoText5 = true"
                                @mouseleave="infoText5 = false"></span>
                                <img src="@/assets/imgs/winner-daily.png" class="img-fluid" width="200px">
                                <div class="d-flex justify-content-between flex-column align-items-start ms-2">
                                    <p class="text-start box-border px-2 py-1">Napi 4x</p>
                                    <p class="text-start">Heineken ajándékcsomag</p>
                                </div>
                                <div class="position-absolute h-100 d-flex justify-content-center align-items-center winnings-text-box" style="border-radius: 0px 40px 40px 40px;top:0;left:0" v-if="infoText5">
                                    <p class="text-white fw-400 font-primary fs-14">1 darab hátizsák, 1 darab 6 x 0,25 l-es pohárkészlet, 1 darab söralátéttartó, 1 darab sörnyitó és kb. 90 darab söralátét</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 col-md-8 offset-md-2 offset-lg-0 mt-lg-0 mt-3 text-center">
                            <div class="d-flex justify-content-between position-relative flex-md-row flex-column bg-white p-2 winnings-box" style="border-radius: 40px 40px 0px 40px;">
                                <span class="col-12 position-absolute shadow-box" @mouseover="infoText4 = true"
                                @mouseleave="infoText4 = false"></span>
                                <img src="@/assets/imgs/winner-weekly.png" class="img-fluid" width="200px">
                                <div class="d-flex justify-content-between flex-column align-items-start ms-2">
                                    <p class="text-start box-border px-2 py-1">Heti 2x</p>
                                    <p class="text-start">Heineken partycsomag</p>
                                </div>
                                <div class="position-absolute h-100 d-flex justify-content-center align-items-center winnings-text-box" style="border-radius: 40px 40px 0px 40px;top:0;left:0" v-if="infoText4">
                                    <p class="text-white fw-400 font-primary fs-14">1 darab hűtőláda, valamint 1 db 24 x 0,5 l dobozos Heineken Original vagy 1 db 24 x 0,5 l dobozos Heineken 0.0 vagy 1 db 24 x 0,33 l dobozos Heineken Silver.<br><br>A nyertesek ezenkívül a hűtőláda átvételét követően 3 hónapig havi 2 tálca sört kapnak (választható 0,5 l dobozos Heineken Original vagy 0,5 l dobozos Heineken 0.0 vagy 0,33 l dobozos Heineken Silver).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="text-center text-white my-5 py-5">
                Extra nyeremények
            </h2>
            <div class="container-xl py-0 position-relative">
                <div class="row cap ">
                    <div class="col-12 col-lg-10 offset-lg-1 text-center">
                        <div class="row winnings-box position-relative" style="border-radius: 40px 40px 0px 40px;opacity: 0.4;background-color: #00793B;">
                            <span class="col-12 position-absolute shadow-box" @mouseover="infoText1 = false"
                            @mouseleave="infoText1 = false"></span>
                            <div class="col-12 col-md-6 px-0">
                                <img src="@/assets/imgs/winner-extra1.jpg" class="img-fluid">
                            </div>
                            <div class="col-12 col-md-6 bg-white d-flex">
                                <div class="d-flex justify-content-between flex-column align-items-start p-2 my-4">
                                    <p class="text-start box-border px-2 py-1">sorsolás június 19-én</p>
                                    <p class="text-start mb-0">Forma 1 Magyar Nagydíj lelátójegy<br><span class="fw-400 font-primary">5 x páros Forma 1 Magyar Nagydíj vasárnapi Grand Stand lelátójegy</span></p>
                                </div>
                            </div>
                            <div class="col-12 position-absolute h-100 d-flex justify-content-center align-items-center winnings-text-box" v-if="infoText1" style="border-radius: 40px 40px 0px 40px;">
                                <p class="text-white fw-400 font-primary fs-18">A Forma-1  a világ legprofibb és legnépszerűbb autóversenye, a Magyar Nagydíj pedig az év egyik legnagyobb hazai sporteseménye!<br><br>A nyeremény tartalma: 2024. július 21-ére, vasárnapra szóló páros jegy a Hungaroring Grand Stand lelátójára.</p>
                            </div>
                        </div>
                        <div class="row winnings-box my-4 position-relative" style="border-radius: 40px 0px 40px 40px;opacity: 0.4;background-color: #00793B;">
                            <span class="col-12 position-absolute shadow-box" @mouseover="infoText2 = false"
                            @mouseleave="infoText2 = false"></span>
                            <div class="col-12 col-md-6 px-0">
                                <img src="@/assets/imgs/winner-extra2.jpg" class="img-fluid">
                            </div>
                            <div class="col-12 col-md-6 bg-white d-flex">
                                <div class="d-flex justify-content-between flex-column align-items-start p-2 my-4">
                                    <p class="text-start box-border px-2 py-1">sorsolás június 19-én</p>
                                    <p class="text-start mb-0">Heineken Balaton Sound Fesztivál belépőjegy<br><span class="fw-400 font-primary">10 x páros szombati VIP Heineken Balaton Sound napijegy</span></p>
                                </div>
                            </div>
                            <div class="col-12 position-absolute h-100 d-flex justify-content-center align-items-center winnings-text-box" v-if="infoText2" style="border-radius: 40px 0px 40px 40px;">
                                <p class="text-white fw-400 font-primary fs-18">Bulizz a legjobb nyári beachfesztiválon! Vár a Heineken Balaton Sound!<br><br>A nyeremény tartalma: 2024. július 6-ára, szombatra szóló páros VIP-napijegy.</p>
                            </div>
                        </div>
                        <div class="row winnings-box position-relative" style="border-radius: 0px 40px 40px 40px;opacity: 0.4;background-color: #00793B;">
                            <span class="col-12 position-absolute shadow-box" @mouseover="infoText3 = false"
                            @mouseleave="infoText3 = false"></span>
                            <div class="col-12 col-md-6 px-0">
                                <img src="@/assets/imgs/winner-extra3.jpg" class="img-fluid">
                            </div>
                            <div class="col-12 col-md-6 bg-white d-flex">
                                <div class="d-flex justify-content-between flex-column align-items-start p-2 my-4">
                                    <p class="text-start box-border px-2 py-1">sorsolás június 19-én</p>
                                    <p class="text-start mb-0">Kolorádó Fesztivál bérlet<br><span class="fw-400 font-primary">5x páros Kolorádó Fesztivál bérlet</span></p>
                                </div>
                            </div>
                            <div class="col-12 position-absolute h-100 d-flex justify-content-center align-items-center winnings-text-box" v-if="infoText3" style="border-radius: 0px 40px 40px 40px;">
                                <p class="text-white fw-400 font-primary fs-18">Kolorádó: ahol a zene és a természet találkozik.<br><br>A nyeremény tartalma: páros bérlet a Kolorádó fesztiválra és kolorádó merchcsomag.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pt-5">
                <p class="text-center text-white fs-14">A képek illusztrációk!<br><span class="fs-16">Részletek a <a class="text-white text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzatban.</a></span></p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  data() {
    return {
      infoText1: false,
      infoText2: false,
      infoText3: false,
      infoText4: false,
      infoText5: false,
      infoText6: false
    }
  }
};
</script>

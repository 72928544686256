<template>
    <div id="regisztracio" class="register block--big">
        <div class="container">                        
            <div class="row" v-if="$store.state.promoStatus == 'live' ">                
                <!-- ITT KEZDŐDIK A FORM -->
                <ValidationObserver 
                    class="col-12 col-xl-10 offset-xl-1 position-relative"
                    ref="observerReg" 
                    v-slot="{ handleSubmit }"
                    tag="div"
                >
                    <div class="row position-relative">                
                        <div class="col-12 col-xl-10 offset-xl-1 mb-0 mb-md-2 metal-thing-sec">
                            <h2 class="font-header text-white text-center mb-6 mb-sm-4 mt-0 fw-800">Regisztráció</h2>
                            <p class="text-white fw-400 text-center mb-7 pb-xl-6">Személyes adataid úgy add meg, hogy azokat személyi igazolványoddal és lakcímkártyáddal tudd igazolni, amennyiben nyerteseink között tudhatunk majd. Az adatok megadása, így a játékban való részvétel önkéntes.<br><br><b>Regisztrációddal felgyorsíthatod a kódfeltöltésed folyamatát.</b></p>
                        </div>
                    </div>    
                        <form
                        class="form-inner-container form__inner" style="border-radius:20px;" 
                        @submit.prevent="handleSubmit(onSubmit)"
                    >                
                        <div class="row cap metal-thing-right">
                            <div class="col-12 text-center mb-5" style="border-bottom: 1px solid #e7e5e5;">
                                <img src="@/assets/imgs/logo-form.svg" alt="Heineken" class="logo pb-5" />
                            </div>
                            <!-- VEZETÉKNÉV -->
                            <!-- todo: regex:^[a-zA-Z\s]*$ -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Vezetéknév"
                                    name="surname"
                                    placeholder="pl.: Molnár"
                                    tooltip="A játékos személyi igazolványába jegyzett vezetéknév."
                                    v-model="form.surname"
                                    rules="required|min:2|customRegex"
                                />  
                            </div>
                            
                            <!-- KERESZTNÉV -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Keresztnév"
                                    name="forename"
                                    placeholder="pl.: Kata"
                                    tooltip="A játékos személyi igazolványába jegyzett keresztnév."
                                    v-model="form.forename"
                                    rules="required|min:2|customRegex"
                                />
                            </div>
                            
                            <!-- EMAIL -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="E-mail cím"
                                    name="email"
                                    placeholder="pl.: molnarkata@gmail.com"
                                    tooltip="A játékos e-mail címe."
                                    v-model="form.email"
                                    rules="required|email"                                    
                                />
                            </div>
                            <!-- EMAIL MÉGEGYSZER -->
                             <div class="col-12 col-md-6">
                                <CustomInput
                                    label="E-mail cím még egyszer"
                                    name="emailConf"
                                    placeholder="pl.: molnarkata@gmail.com"
                                    tooltip="A játékos e-mail címe újra."
                                    v-model="form.emailConf"
                                    rules="required|email|confirmed:email"
                                />
                            </div>

                            <!-- JELSZÓ -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Jelszó"
                                    type="password"
                                    name="password"
                                    placeholder="Minimum 8 karakter"
                                    tooltip="Legalább 8 karakternek kell lennie és speciális karaktereket tartalmazhat."
                                    v-model="form.password"
                                    rules="required|min:8"
                                />
                            </div>

                            <!-- JELSZÓ MEGERŐSÍTÉS -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Jelszó még egyszer"
                                    type="password"
                                    name="passconf"
                                    placeholder="Minimum 8 karakter"
                                    tooltip="Jelszó még egyszer."
                                    v-model="form.passconf"
                                    rules="required|confirmed:password"
                                />
                            </div>

                            <!-- TELEFONSZÁM -->
                            <div class="col-12 col-md-6 phone-wrap">
                                <div class="form-label">Telefonszám</div>
                                <div class="d-flex w-100 align-items-start position-relative">
                                    <span class="me-2 d-block phone-prefix">+36</span>
                                    <CustomInput
                                        class="w-100"
                                        label=""
                                        name="phone"
                                        tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                        v-model="phoneShort"                                        
                                        @input="formatPhoneNumber($event)"                                        
                                        rules="customPhone"
                                    />
                                </div>
                            </div>

                            <!-- JÁTÉKSZABÁLY ELFOGADÁSA -->
                            <div class="col-12">
                                <CheckBox
                                    class="mt-4"
                                    name="condition"
                                    id="condition0"
                                    v-model="form.condition"
                                    :checked="form.condition"
                                    rules="required|acceptConditions"                                  
                                >
                                    <p 
                                        class="mb-0 text-black"
                                    >
                                        Kijelentem, hogy 18 éves elmúltam, a <span>&nbsp;</span>
                                        <a class="text-primary text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzatot</a>
                                        <span>&nbsp;</span> elolvastam, annak feltételeit elfogadom, és az adatkezeléssel kapcsolatos, a 12. pontban foglalt tájékoztatást kifejezetten tudomásul vettem.
                                        <span class="text-red fw-bold">*</span>
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- ÁSZF ELFOGADÁSA -->
                            <div class="col-12">
                                <CheckBox
                                    class="mt-2"
                                    name="consent"
                                    id="consent0"
                                    v-model="form.consent"
                                    :checked="form.consent"
                                    rules="required|acceptConditions"
                                >
                                    <p class="mb-0 text-black"> 
                                        <span>Hozzájárulok ahhoz, hogy a nyertesként történő kisorsolásom esetén a szervező a vezetéknevem kezdőbetűjét és a keresztnevemet, a nyerés tényét, valamint a nyeremény megjelölését az alábbi honlapon közzé tegye: <a class="text-primary text-underline fw-700" href="https://heineken.hu/nyeremenyjatek">heineken.hu/nyeremenyjatek.</a> Az adatkezelésre vonatkozó részletes információkat a hivatalos </span>
                                        <span>&nbsp;</span>
                                        <a class="text-primary text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzat 14. pontja</a>
                                        <span>&nbsp;</span>
                                        <span>tartalmazza.</span>
                                        <span class="text-red fw-bold">*</span>    
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- FELIRATKOZÁS A HÍRLEVÉLRE -->
                           <div class="col-12">
                                <CheckBox
                                    class="mt-2"
                                    name="newsletter"
                                    id="newsletter0"
                                    v-model="form.newsletter"
                                    :checked="form.newsletter"
                                    @input="changeMarketing()"
                                >
                                    <div>
                                        <p class="text-black">Hozzájárulok ahhoz, hogy az Adatkezelő közvetlen marketing célú megkereséseket küldjön a részemre, például email-ben, vagy egyéb módon (opt-in).</p>
                                    </div>
                                </CheckBox>
                            </div>
                            
                            <div class="col-12 col-md-6">
                                <SelectDate v-if="form.newsletter === 1"
                                    name="birth_date"
                                    label="Kérlek add meg a születési dátumod" 
                                    placeholder="ÉÉÉÉ-HH-NN"
                                    @selectedDate="form.birth_date = dateTimeToSqlFormat($event)"
                                    :rules="form.newsletter != 0 ? 'required':''"
                                />                          
                            </div>

                           <div class="mt-3 mb-md-4 text-sm-center d-flex">                                
                                    <span class="text-red fw-bold">*</span>                                    
                                    <p class="m-0 info-text text-black">A csillaggal jelölt mezők kitöltése szükséges.</p>
                            </div>                         

                            <!-- SUBMIT --> 
                            
                            <div class="col-12 d-flex justify-content-center mt-md-5 mt-0">
                                <button 
                                    class="btn btn-secondary btn-submit" 
                                    type="submit"
                                    @click="checkErrors();gtag_report_conversion()"
                                >Regisztrálok<img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                                </button>
                            </div>                            
                        </div>
                    </form>
                </ValidationObserver> <!-- ITT VÉGZŐDIK A FORM -->
            </div>          
        </div>
        <!-- REGISTRATION RESULT MODAL -->
        <!-- <RegisterSuccessModal :email="sendedEmail" :isSuccessReg="regSuccess"/> -->
    </div>
    
</template>

<script>
/**
 * after the reg go to the reg result page
 */

import { ValidationObserver } from 'vee-validate'
import CustomInput from '@/components/base/CustomInput.vue'
//import DropDown from '@/components/base/DropDown.vue'
import CheckBox from '@/components/base/CheckBox.vue'
import SelectDate from '@/components/base/SelectDate.vue'
// import RegisterSuccessModal from '@/components/RegisterSuccessModal.vue'
// import errorMessages from "@/setup/errorMsgs.json";
import {mask} from 'vue-the-mask'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomInput,
       // DropDown,
        CheckBox,
        SelectDate,
        // RegisterSuccessModal
    },
    data () {
        return {
            options: [],  
            phoneShort: '',
            phoneRaw: '',
            form: {
                surname: '',
                forename: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
                condition: null,
                consent: null,
                newsletter: 0,
                birth_date:'',                                    
           },        
        }
    },
    computed:{
        formToSend(){          
            let data = this.form     
            
            return data
        }
    },
    methods: {
        changeMarketing(){
            if ( parseInt(this.form.newsletter) === 0){
                this.form.birth_date = ''
            }
        },
        scrollToElement(element, diff){
            
            const offset = document.querySelector('.navbar').clientHeight + diff
            const bodyTop = document.body.getBoundingClientRect().top;
            const elementTop = element.getBoundingClientRect().top;
            const elementPos = elementTop - bodyTop;
            const offsetPos = elementPos - offset;

            window.scrollTo({
                top: offsetPos,
                behavior: 'smooth'
            });
        },
        onSubmit(){
            if (this.phoneShort){
                this.form.phone = '+36-' + this.phoneShort
            }

            var sendData = JSON.parse(JSON.stringify(this.formToSend));            

            this.post('register',sendData).then((res)=>{
                console.log(typeof res.data.status, res)
                if (res.data.status == true){                    
                    //this.regSuccess = true   

                    window.dataLayer.push({
                        'event' : 'event',
                        'category': 'regisztracio',
                        'action': 'gomb_kattintas',                                
                        'clicked_text':'Regisztráció',
                        'action_type': 'gomb_kattintas',
                        'label': 'sikeres',
                        'success': 'sikeres'
                    })

                    if(this.$store.state.isMarketingCookieActive){ 
                       // console.log("marketing cookie lefut");
                        window.fbq('track', 'CompleteRegistration');

                        window.gtag('event', 'conversion', {
                            'allow_custom_scripts': true,
                            'send_to': 'DC-10798373/reg/hucrreg0+unique'
                        });                       
                       
                    }

                    //go to the reg 
                    this.$router.push({name: 'successReg', params: {result: 'success'}})
                    
                    //reset form
                    this.setFormDefaultState()       
                  // this.setGtm('sikeres')                 
                }
                                
           }).catch((err)=>{       
                console.log(err);                   
                                   
                this.goToError(err)                                                          
           })
      
        },
        gtag_report_conversion(url){
            if(this.$store.state.isMarketingCookieActive){
                var callback = function () {
                    if (typeof(url) != 'undefined') {
                    window.location = url;
                    }
                };
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-775738373/RnrqCP6Eza0ZEIWo8_EC',
                    'event_callback': callback
                });
                return false;
            }

        },
        analyzeErrors(err){
            return new Promise((resolve, reject) => {
                let errorsArray = Object.entries(err.response.data.error)
                for (const [errorName, errorValue] of errorsArray) {
                    // if (errorName == "email" && errorValue == "occupied" ){
                    //     this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'occupied'}})
                    //     reject()
                    // } 
                    if (errorName == 'maximum_registration_same_ip' && errorValue == 'limit_reached'){
                        this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'limit_reached'}})
                        reject()
                    } else{
                        resolve()
                    }
                }
            })
        },
        checkErrors(){
            let _this = this;
            setTimeout( function(){
                let firstError = document.querySelector('.register .input-error')
                if (firstError)                    
                    _this.scrollToElement(firstError, 80)
            }, 500)
        },
        goToError(err){            
            this.analyzeErrors(err).then(()=>{
                this.setErrorMsgs(err, 'observerReg').then(()=>{                
                    let firstError = document.querySelector('.register .input-error.active-error')
                    if (firstError)
                        this.scrollToElement(firstError, 80)       
                }).catch(()=>{
                    this.$router.push({name: 'successReg', params: {result: 'not-success'}})
                })   
            })
                                              
        },      
        setFormDefaultState(){
            this.form = {
                surname: '',
                forename: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
                condition: null,
                consent: null,
                newsletter: 0,
                birth_date:'',
           }
           this.phoneShort = '';
           this.phoneRaw = '';
        },
        setGtm(result){
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'regisztracio',
                'action': 'gomb_kattintas',                                
                'clicked_text':'Regisztráció',
                'action_type': 'gomb_kattintas',
                'label': result,
                'success': result
            })
        
        }
    }
}
</script>
<template>
    <div>
        <!-- NYertesek -->
        <div class="winners">
            <div class="container-xl block--big py-5" id="nyertesek">
                <div class="row">  
                    <h2 class="text-center mt-0 text-white mb-5 pb-5">
                       Koccintsunk a nyertesekre!
                    </h2>   
                    <div class="col-12 col-lg-10 offset-lg-1">
                        <!-- TAB -->
                        <div class="winners-button-select mb-5 p-1 d-none d-sm-flex" >
                            <button 
                                class="btn fw-700" 
                                :style="winnerState === 'daily' ? 'background: white;color:#00793B;':''" 
                                v-on:click="setWinnerState('daily')"
                            >
                                Napi <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button> 
                            <button 
                                class="btn fw-700"
                                :style="winnerState === 'weekly' ? 'background: white;color:#00793B;':''" 
                                v-on:click="setWinnerState('weekly')"
                            >
                                Heti <span class="d-none d-md-block ms-1">nyertesek</span>
                            </button>
                            <button 
                                class="btn fw-700" 
                                :style="winnerState === 'extra' ? 'background: white;color:#00793B;':''" 
                                v-on:click="setWinnerState('extra')"
                            >
                                Extra <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button>
                            <button 
                                class="btn fw-700" 
                                :style="winnerState === 'main' ? 'background: white;color:#00793B;':''" 
                                v-on:click="setWinnerState('main')"
                            >
                                A fődíj <span class="d-none d-md-block ms-1"> nyertese</span>
                            </button>
                        </div>

                        <div class="dropdown winners-button-select--mobile d-block d-sm-none text-center">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <span class="text-primary" v-if="winnerState == 'main'">Fődíj</span>
                                <span class="text-primary" v-else-if="winnerState == 'weekly'">Heti</span>
                                <span class="text-primary" v-else-if="winnerState == 'daily'">Napi</span>
                                <span class="text-primary" v-else-if="winnerState == 'extra'">Extra</span>
                            </button>
                            <div class="dropdown-menu fw-bold text-primary" aria-labelledby="dropdownMenuButton">                
                                <div class="dropdown-item" v-on:click="setWinnerState('daily');setGTM('nyertesek_napi')">Napi</div>
                                <div class="dropdown-item" v-on:click="setWinnerState('weekly');setGTM('nyertesek_heti')">Heti</div>
                                <div class="dropdown-item" v-on:click="setWinnerState('extra');setGTM('nyertesek_heti')">Extra</div>
                                <div class="dropdown-item" v-on:click="setWinnerState('main');setGTM('nyertesek_heti')">Fődíj</div>
                            </div>
                        </div>                   
                        <!-- TABLE -->
                        <div class="winners__border mb-5">
                            <div class="winners__table-wrap winners__table-wrap--heading">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                    <thead>
                                        <tr class="winners-top font-primary" >
                                            <th class="w-25  text-left" style="color:#064D2B;">Nyertes neve</th>
                                            <th class="w-25  text-left" style="color:#064D2B;">Nyertes kód</th>
                                           <!-- <th class="w-25 text-white">Típus</th> -->
                                            <th class="w-50  text-left" style="color:#064D2B;">Nyeremény</th>                            
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="winners__table-wrap winners__table-wrap--bg">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                    <tbody v-if="filteredWinners && filteredWinners.length && this.$store.state.loginShow">                          
                                        <tr class="winners-list fs-14 text-white font-secondary" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                            <td class="winners-id fw-700 text-left w-25 p-3" style="color: #7C7C7C;">
                                                <div>{{ winner.name }}</div>                                     
                                            </td>                          
                                            <td class="winners-product text-left w-25 p-3" style="color: #7C7C7C;">
                                                <span>{{winner.winning_time}}</span>
                                            </td>
                                            <!--<td class="winners-product text-white text-left  w-25 p-3">
                                                <span>{{winner.winning_type}}</span>
                                            </td>-->
                                            <td class="winners-product text-left fw-700  w-50 p-3" style="color: #00793B;">
                                                <span v-if="winner.type == 'weekly'">1 db Heineken partycsomag</span>
                                                <span v-else-if="winner.type == 'extra_forma_1'">1 db páros Forma 1 lelátójegy</span>
                                                <span v-else-if="winner.type == 'extra_kolorado'">1 db páros Kolorádó fesztivál belépő</span>
                                                <span v-else-if="winner.type == 'extra_balaton_sound'">1 db páros Balaton Sound belépő</span>
                                                <span v-else-if="winner.type == 'main'">Amszterdami utazás</span>
                                                <span v-else>1 db Heineken ajándékcsomag</span>
                                            </td>
                                        </tr>                            
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-if="this.$store.state.loginShow">
                                            <td class="no-winner text-primary text-center py-3" colspan="2">
                                                Hamarosan kiderül, kik lettek a nyertesek
                                            </td>                                   
                                        </tr>
                                        <tr v-else>
                                            <td class="no-winner text-primary text-center py-3" colspan="2">
                                                A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                        
                            </div>

                            
                        </div>

                        <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                                <button target="_blank" type="button" class="btn btn-border" v-on:click="moreWinners(true)">Mutasd a további nyerteseket</button>
                        </div>
                    </div>           
                    
                </div> 
            </div> 
        </div>
        <div class="bg-secondary py-5 text-center">
            <p class="text-white mb-0 py-md-3 mx-2 mx-md-0 font-primary fs-16">Ne feledd, mindenképpen őrizd meg a nyitófület vagy a kupakot, hiszen a nyertesek csak ennek felmutatásával vehetik át a nyereményüket!</p>
        </div>
    </div>
</template>


<script>
export default {
    data () {
        return {
            winnersAll:[],
            winnerState:'daily',
            needMoreBtn: false,
            needMoreWinners: false
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            //let _this = this
        
            if(this.$store.state.winnersAll ?? []){                

                result = this.$store.state.winnersAll.filter(w => w.type === this.winnerState)

               this.winnersAll.forEach(w => {
                    if (this.winnerState == 'extra'){
                        if (w.type === 'extra_forma_1' || w.type === 'extra_balaton_sound' || w.type === 'extra_kolorado'){
                            result.push(w)
                        }
                    }/* else {
                        if (_this.winnerState == 'heti'){
                            if (w.type != 'napi' && w.type != 'fodij'){                            
                                result.push(w)
                            }
                        }
                    }*/

                })

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    methods: {
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
        selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.$store.state.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }
    },
    created() {
        this.$store.dispatch("getWinners");    
        
    },
    updated(){
        this.winnersAll = this.$store.state.winnersAll;
    }
    
}
</script>
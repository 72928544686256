<template>    
    <div class="hero__outer">
        <div class="hero hero__inner" id="hero">        
            <div class="hero-container">
                <!-- CONTENT -->
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-xl-5 offset-xl-5 col-lg-8 offset-lg-4 col-md-10 offset-md-2 d-flex align-items-center position-relative justify-content-center">
                            <div class="row cap metal-thing">
                                <div class="col-12 py-lg-5 col-md-8 col-lg-12 offset-md-4 offset-lg-0 text-center text-md-start text-white">
                                    <h1 class="d-none d-md-block text-uppercase" v-if="$store.state.promoStatus == 'live'">Játssz a kóddal<br><span>és nyerj amszterdami utazást vagy<br> további Heineken élményeket!</span></h1>
                                    <h1 class="d-block d-md-none text-uppercase mt-4" v-if="$store.state.promoStatus == 'live'">Játssz a kóddal<br><span class="mt-3"> és nyerj  amszterdami utazást vagy további Heineken élményeket!</span></h1>
                                    <h1 v-if="$store.state.promoStatus == 'before'">Nyereményjátékunk<br><span>hamarosan kezdődik</span></h1>
                                </div>
                              <!--  <div class="col-12 py-lg-5 text-center text-lg-start" v-if="$store.state.promoStatus == 'after'">
                                    <img src="@/assets/imgs/hero-closed.png" class="img-fluid d-none d-lg-block" width="500"/>
                                </div> -->
                                 <div class="col-12 col-md-8 offset-md-4 offset-lg-0 col-lg-10 col-xxl-10 text-center text-md-start" v-if="$store.state.promoStatus == 'before'">
                                    <h3 class="text-white">Játssz velünk 2024. május 16. és július 17. között!</h3>
                                    <p class="text-white mt-5 d-none d-md-block">Töltsd fel a promóciós Heineken® kupakokban, illetve nyitófüleken található kódokat, és nyerd meg a napi, heti, valamint az extra nyereményekegyikét vagy a 4 fős utat Amszterdamba!</p>
                                 </div>
                                <div class="col-12 col-md-8 offset-md-4 offset-lg-0 col-lg-10 col-xxl-10 d-none d-md-block" v-if="$store.state.promoStatus == 'live'">
                                    <div class="game-rules pt-md-0 pt-5 pl-4">
                                        <ul>
                                            <li class="position-relative d-flex justify-content-start align-items-center">
                                                <img src="@/assets/imgs/icon-step-1.png" class="img-fluid step"/>
                                                <p class="mb-0 ms-2">Vásárolj promóciós csomagolású Heineken® terméket!</p>
                                            </li>
                                            <li class="position-relative d-flex justify-content-start align-items-center">
                                                <img src="@/assets/imgs/icon-step-2.png" class="img-fluid step">
                                                <p class="mb-0 ms-2">Töltsd fel a kupakban vagy nyitófülön található kódot 2024. július 17-ig!</p>
                                            </li>
                                            <li class="position-relative d-flex justify-content-start align-items-center">
                                                <img src="@/assets/imgs/icon-step-3.png" class="img-fluid step"/>
                                                <p class="mb-0 ms-2">Fotózd le a kódot és őrizd meg a kupakot vagy a nyitófület!</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-12 col-md-8 offset-md-4 offset-lg-0 col-lg-10 col-xxl-10 text-center text-md-start" v-if="$store.state.promoStatus == 'after'">
                                    <h1 class="text-white">Nyereményjátékunk<br><span class="mt-2">2024. július 17-én éjfélkor lezárult.</span></h1>
                                    <p class="text-white fs-16">Köszönjük, hogy velünk játszottál!<br>A nyertesek listájához görgess lejjebb!</p>
                                 </div>
                                <div class="col-12 col-md-8 offset-md-4 offset-lg-0 col-lg-12 text-lg-start text-center d-none d-md-block">
                                    <div class="text-left text-dark mt-4">
                                        <a v-if="$store.state.promoStatus == 'live'"
                                            class="btn btn-secondary mb-3 order-md-2 me-md-2 md-btn"
                                            :href="!$store.state.user ? '#regisztracio' : '#palyazat'"
                                            @click="setGtm()"
                                        >
                                            Játszom!
                                            <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                                        </a>
                                        <a class="btn btn-border me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" v-if="$store.state.promoStatus != 'before'">
                                            Játékszabályzat
                                        </a>
                                        <a class="btn btn-border me-0 ms-lg-2 order-md-1 mb-3 md-btn" href="#nyertesek" v-if="$store.state.promoStatus == 'after'">
                                            nyertesek
                                        </a>
                                    </div>           
                                </div>         
                            </div>
                        </div>
                    </div>   
                </div>     
            </div>  
            <img src="@/assets/imgs/hero-mobile.jpg" class="img-fluid d-block d-md-none w-100" />
            <div class="col-12 col-md-8 offset-md-4 offset-lg-0 col-lg-10 col-xxl-10 d-block d-md-none bg-primary" v-if="$store.state.promoStatus == 'live'">
                <div class="game-rules pt-md-0 pt-4 pl-4 px-3">
                    <ul class="mb-0">
                        <li class="position-relative d-flex justify-content-start align-items-center">
                            <img src="@/assets/imgs/icon-step-1.png" class="img-fluid step"/>
                            <p class="mb-0 ms-2">Vásárolj promóciós csomagolású Heineken® terméket!</p>
                        </li>
                        <li class="position-relative d-flex justify-content-start align-items-center my-3">
                            <img src="@/assets/imgs/icon-step-2.png" class="img-fluid step">
                            <p class="mb-0 ms-2">Töltsd fel a kupakban vagy nyitófülön található kódot 2024. július 17-ig!</p>
                        </li>
                        <li class="position-relative d-flex justify-content-start align-items-center">
                            <img src="@/assets/imgs/icon-step-3.png" class="img-fluid step"/>
                            <p class="mb-0 ms-2">Fotózd le a kódot és őrizd meg a kupakot vagy a nyitófület!</p>
                        </li>
                    </ul>
                </div>
                <div class="text-left text-dark mt-4 mx-4">
                    <a v-if="$store.state.promoStatus == 'live'"
                        class="btn btn-secondary mb-3 order-md-2 me-md-2 md-btn"
                        :href="!$store.state.user ? '#regisztracio' : '#palyazat'"
                        @click="setGtm()"
                    >
                        Játszom!
                        <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                    </a>
                    <a class="btn btn-border me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" v-if="$store.state.promoStatus != 'before'">
                        Játékszabályzat
                    </a>
                </div>  
            </div>
            <div class="py-4 text-center text-white bg-primary d-block d-md-none" v-if="$store.state.promoStatus == 'before'">                               
                <p class="mb-0 font-primary fs-16 px-3" style="line-height:23px;">Töltsd fel a promóciós Heineken® kupakokban, illetve nyitófülekentalálható kódokat, és nyerd meg a napi, heti, valamint az extra nyereményekegyikét vagy a 4 fős utat Amszterdamba!</p>
            </div> 
            <div class="py-4 text-center text-white" style="background-color: #064D2B;">                               
                <p class="mb-0 font-secondary hero__info-text px-3">A promóció időtartama: 2024. 05. 16. - 07. 17. A promócióban csak 18 éven felüliek játszhatnak.<br>Azért, hogy a nyereményeket biztosan át tudjuk adni, kérjük, hogy a kupakokat és a nyitófüleket a játék vége után 2 hónapig (2024. 09. 17-ig), illetve a nyeremények átadásáig őrizd meg!</p>
            </div>              
        </div>
    </div>            
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(){
            //console.log("cookie: ", this.$store.state.isMarketingCookieActive);
            if(this.$store.state.isMarketingCookieActive){ 
               
                window.gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': 'DC-10798373/landing/hucrplay+unique'
                });

                window.fbq('trackCustom', 'PlayGame');
            }
          
        }
    }
}
</script>